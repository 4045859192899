import { ApplicantAddressResponse } from 'entities/applicant'

export function formatLongForm(address?: ApplicantAddressResponse): string {
  if (!address?.longForm) return ''

  const [streetPart, ...rest] = address.longForm.split(', ')
  const country = rest.at(-1)
  const subdivisionLine = rest.at(-2)
  const postcode = subdivisionLine?.match(/\d{1,}/g)
  const subdivision = postcode ? subdivisionLine?.replace(postcode[0], '') : ''

  const parts = [streetPart]

  if (address.suburb) {
    parts.push(address.suburb)
  }

  return [...parts, subdivision?.trim(), postcode?.[0], country].join(', ')
}
