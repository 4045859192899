export const externalLinks = {
  support: 'https://www.frankieone.com/contact-us',
  documentation: 'https://docs.frankieone.com',
  documentationV2: 'https://docs.frankieone.com/v2.0.0/docs/welcome-to-frankie',
  mfaDocs: 'https://docs.frankieone.com/docs/multi-factor-authentication',
  globalSearchDocs: 'https://docs.frankieone.com/docs/portal-global-search',
  systemMonitoring: process.env.VUE_APP_MONITORING_PAGE_URL || '',
  updateLegacyProfile:
    'https://docs.frankieone.com/docs/updating-legacy-profile-to-recipe',
  whatIsRecipe: 'https://docs.frankieone.com/docs/what-is-a-recipe',
  supportingDocs: 'https://docs.frankieone.com/docs/working-with-support-docs',
} satisfies Record<string, string>
